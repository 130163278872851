<template>
  <van-dialog
    v-model="show"
    class="dialog"
    show-cancel-button
    @close="confirm"
    @confirm="confirm"
  >
    <van-form style="position: relative; margin-top: 2rem">
      <van-cell-group inset>
        <van-sticky>
          <van-field v-model="formData.name" name="name" label="类目名称" />
          <!-- <van-field
            v-model="formData.available"
            required
            name="available"
            label="状态"
            placeholder="是否启用"
          >
            <template v-slot:input>
              <van-dropdown-menu style="width:100%">
                <van-dropdown-item
                  v-model="formData.available"
                  :options="statusList"
                />
              </van-dropdown-menu>
            </template>
          </van-field> -->
          <van-cell>
            <van-button type="primary" @click="search">查询</van-button>
          </van-cell>
        </van-sticky>
      </van-cell-group>
      <div class="tableBox">
        <CustomTable
          :scrollWidth="200"
          :columns="columns"
          :tableData="tableData"
          :showPagination="false"
          @selectedRowChange="showActive"
          :total="totalCount"
          :pageIndex="pageIndex"
          :pageSize="pageSize"
          @pageChange="pageChange"
        ></CustomTable>
      </div>
      <!-- <van-list
        class="scrollList"
        :finished="true"
        :loading-text="'加载中...'"
        :finished-text="'没有更多了'"
        @load="getProductList"
        :error="true"
        :error-click="getProductList"
      >
        <van-cell
          ref="cell"
          v-for="item in categoryList"
          :key="item.name"
          :title="item.name"
          @click="showActive(item)"
        />
      </van-list> -->
    </van-form>
    <!-- <van-cell style="position: absolute;bottom:0;">
      <van-button round block type="primary" @click="confirm">
        提交
      </van-button>
    </van-cell> -->
  </van-dialog>
</template>
<script>
import { AVAILABLE } from "../utils/constants";
import { reqWeightCategory } from "../service/api/product";
import CustomTable from "./customTable.vue";

// 导入样式
export default {
  name: "categorySelect",
  components: {},
  props: {
    visible: false,
  },
  components: { CustomTable },
  data() {
    return {
      tableData:[],
      columns: [
      {
          field: "radio",
          key: "b",
          // type=radio
          type: "radio",
          title: "单选",
          width: 20,
          align: "left",
        },
        { field: "code", key: "code", title: "编号",width:50 },
        { field: "name", key: "name", title: "名称",width:50 },
    ],
      pageSize: 20,
      pageIndex: 1,
      totalCount: 0,
      totalPage: 1,
      show: false,
      data: [],
      formData: { name: "", available: true },
      // columns: [
      //   {
      //     label: "编号",
      //     prop: "id",
      //   },
      //   {
      //     label: "名称",
      //     prop: "name",
      //   },
      //   {
      //     label: "状态",
      //     prop: "available",
      //   },
      // ],
      statusList: AVAILABLE,
      categoryList: [],
      currentRow: {},
    };
  },
  watch: {
    visible(newVal, oldVal) {
      this.show = newVal;
    },
  },
  created() {
    this.getProductList();
  },
  methods: {
    search() {
      const ids = this.data.reduce((pre, cur) => {
        if (cur.name.includes(this.formData.name)) {
          pre.push(cur.id);
          if (cur.parentId) {
            pre.push(cur.parentId);
          }
        }
        return pre;
      }, []);
      this.categoryList = this.data.filter((el) => {
        return ids.findIndex((i) => i === el.id) !== -1;
      });
    },
    showActive(item) {
      this.currentRow = item;
      // console.log(item, this.$refs.cell);
      // const exitIndex = this.categoryList.findIndex((i) => i.id === item.id);
      // this.$refs.cell.forEach((el, index) => {
      //   el.classList.remove("active");
      //   if (index == exitIndex) {
      //     el.classList.add("active");
      //   }
      // });
    },
    confirm() {
      console.log(this.currentRow)
      this.$emit("confirm", this.currentRow);
      this.show = false;
      this.currentRow = {};
    },
    pageChange(pageOptions) {
      this.pageIndex = pageOptions.pageIndex;
      this.pageSize = pageOptions.pageSize;
      this.getProductList();
    },
    getProductList() {
      reqWeightCategory(this.formData).then((res) => {
        this.data = res.data;
        this.categoryList = this.data;
        this.tableData = res.data;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.active {
  background: #1989fa;
}
.dialog {
  height: 80%;
}

::v-deep .van-dialog__content {
  height: 100%;

  .van-form {
    height: 100%;
  }
}

.scrollList {
  height: calc(100% - 220px);
                
  overflow: auto;
}
::v-deep .van-dialog__footer {
  position: sticky;
  bottom: 0;
}
</style>
