<template>
  <van-dialog
    v-model="show"
    class="dialog"
    show-cancel-button
    @close="confirm"
    @confirm="confirm"
  >
    <van-form style="position: relative; margin-top: 2rem">
      <van-cell-group inset>
        <van-sticky>
          <van-field v-model="formData.name" name="name" label="品牌名称" />
          <!-- <van-field
            v-model="formData.available"
            required
            name="available"
            label="状态"
            placeholder="是否启用"
          >
            <template v-slot:input>
              <van-dropdown-menu style="width:100%">
                <van-dropdown-item
                  v-model="formData.available"
                  :options="statusList"
                />
              </van-dropdown-menu>
            </template>
          </van-field> -->
          <van-cell>
            <van-button type="primary" @click="search">查询</van-button>
          </van-cell>
        </van-sticky>
      </van-cell-group>
      <van-list
        class="scrollList"
        :finished="true"
        :loading-text="'加载中...'"
        :finished-text="'没有更多了'"
        @load="getProductList"
        :error="true"
        :error-click="getProductList"
      >
        <van-cell
          ref="cell"
          v-for="item in data"
          :key="item.name"
          :title="item.name"
          @click="showActive(item)"
        />
      </van-list>
    </van-form>
  </van-dialog>
</template>
<script>
import { AVAILABLE } from "../utils/constants";
import { reqBrand, reqWeightCategory } from "../service/api/product";
export default {
  name: "brandSelect",
  components: {},
  props: {
    visible: false,
  },
  data() {
    return {
      show: false,
      data: [],
      brandList: [],
      formData: { name: "", available: true },
      columns: [
        {
          label: "编号",
          prop: "id",
        },
        {
          label: "名称",
          prop: "name",
        },
        {
          label: "状态",
          prop: "available",
        },
      ],
      statusList: AVAILABLE,
      currentRow: {},
    };
  },
  watch: {
    visible(newVal, oldVal) {
      this.show = newVal;
      console.log(newVal, oldVal);
    },
  },
  created() {
    this.getProductList();
  },
  methods: {
    search() {
      this.brandList = this.data.filter((el) => {
        return this.formData.name.includes(el.name);
      });
    },
    showActive(item) {
      this.currentRow = item;
      const exitIndex = this.brandList.findIndex((i) => i.id === item.id);
      this.$refs.cell.forEach((el, index) => {
        el.classList.remove("active");
        if (index == exitIndex) {
          el.classList.add("active");
        }
      });
    },
    confirm() {
      this.$emit("confirm", this.currentRow);
      this.show = false;
      this.currentRow = {};
    },
    getProductList() {
      reqBrand(this.formData).then((res) => {
        console.log(res.data, "分类数据");
        this.data = res.data.datas;
        this.brandList = res.data.datas;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.active {
  background: #1989fa;
}
.dialog {
  height: 80%;
}

::v-deep .van-dialog__content {
  height: 100%;

  .van-form {
    height: 100%;
  }
}

.scrollList {
  height: calc(100% - 185px);
                
  overflow: auto;
}
::v-deep .van-dialog__footer {
  position: sticky;
  bottom: 0;
}
</style>
