<template>
  <div>
    <van-uploader
      v-model="file"
      :max-count="1"
      :max-size="1024 * 1000 * 10"
      :accept="'image/*'"
      @oversize="onOversize"
      :after-read="onRead"
    >
      <van-image v-if="defaultSrc" ref="img" :src="defaultSrc"></van-image
    ></van-uploader>
  </div>
</template>
<script>
export default {
  name: "uploadImage",
  props: {
    // value: { default: [], type: Array },
  },
  data() {
    return { file: [], defaultSrc: "" };
  },
  mounted() {},
  watch: {
    $attrs(newVal, oldVal) {
      if (newVal) {
            console.log(newVal);
  this.defaultSrc = newVal.value;
      }
    },
    // value(newVal, oldVal) {
    //   console.log(newVal);
    // },
    file(newVal, oldVal) {
      if (newVal.length) {
        const formData = new FormData();
        formData.append("file", newVal[0].file);
        console.log(newVal, formData);
        this.$emit("change", formData);
        // api.uploadImage(formData).then((res) => {
        //   this.$emit("uploadSuccess", res.data);
        // });
      }
    },
  },
  methods: {
    onRead(file) {
      if (this.$refs && this.$refs.img) this.$refs.img.src = file.content;
    },
    onOversize(file) {
      console.log(file);
      Toast("文件大小不能超过 10MB");
    },

    // beforeRead(file, detail) {
    //   console.log("beforeRead", file, detail);
    // },
  },
};
</script>
<style lang="less" scoped>
.van-uploader {
  width: 100px;
  height: 100px;
  ::v-deep .van-uploader__wrapper {
    width: 100%;
    height: 100%;
  }
  .van-uploader__input-wrapper {
    width: 100%;
    height: 100%;
  }
}
.van-image {
  width: 100%;
  height: 100%;
}
</style>
